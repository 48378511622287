<template>
    <div class="reviewsCard">
        <img class="reviewsCard__icon" v-if="cardIcon" v-bind:src="cardIcon" />
        <div class="reviewsCard__content">
            <p class="h3">
                {{ firstName }}
                {{ lastName }}
            </p>
            <p class="text2 colorGray reviewsCard__content_date">
                {{ cardDate }}
            </p>
            <p class="reviewsCard__content_text">
                {{ cardDescriptionImpression }}
            </p>
            <button class="btn-text reviewsCard__content_button" @click="onClick">
                Подробнее
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "ReviewsCard",
    props: {
        firstName: String,
        lastName: String,
        cardDate: String,
        cardDescriptionImpression: String,
        cardIcon: String,
        onClick: Function,
    },
};
</script>

<style lang="scss">
@import "./reviewsCard.scss";
</style>
