<template>
    <div class="popup-layout">
        <div class="popup popup--4 reviewModal">
            <button type="button" class="popup__close" @click="() => onClose()"></button>
            <div class="reviewModal__reviewsInfo">
                <img
                    class="reviewModal__reviewsInfo_icon"
                    alt="avatarCard2"
                    :src="cardIcon"
                />
                <p class="h3 reviewModal__reviewsInfo_title">
                    {{ firstName }} {{ lastName }}
                </p>
                <p class="text2 colorGullGray">
                    {{ cardDate }}
                </p>
            </div>
            <div class="reviewModal__content">
                <p class="reviewModal__content_text" v-if="cardDescriptionImpression">
                    <span class="h3 q">Отзыв о программе</span>
                    <span>
                        {{ cardDescriptionImpression }}
                        <br />
                        <br />
                        {{ cardDescriptionLiked }}
                        <br />
                        <br />
                        {{ cardDescriptionImprove }}
                    </span>
                </p>
                <!-- <p class="reviewModal__content_text" v-if="cardDescriptionLiked">
                    {{ cardDescriptionLiked }}
                </p>
                <p class="reviewModal__content_text" v-if="cardDescriptionImprove">
                    {{ cardDescriptionImprove }}
                </p> -->
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
export default {
    name: "reviewModal",
    components: { Button },
    props: [
        "onClose",
        "cardDate",
        "firstName",
        "lastName",
        "cardIcon",
        "cardDescriptionImpression",
        "cardDescriptionLiked",
        "cardDescriptionImprove",
    ],
    data() {
        return {};
    },

    methods: {},
};
</script>

<style lang="scss" scoped>
@import "reviewModal.scss";
</style>
