<template>
    <Layout>
        <div class="pageSection">
            <SideBar />

            <div class="pageContent pageContent--3 reviews">
                <div class="reviews__header">
                    <p class="h1">
                        Отзывы
                    </p>
                    <TooltipButton
                        v-if="activeButton"
                        :tooltipButtonText="'НАПИСАТЬ ОТЗЫВ'"
                        :text="
                            'Вы сможете написать свой отзыв после прохождения восьмого блока обучения. Или возможно, Вы уже отправляли отзыв об этом курсе'
                        "
                    />
                    <Button
                        v-else
                        class="comparisonResultButton"
                        extraClass="button"
                        title="НАПИСАТЬ ОТЗЫВ"
                        :onClick="() => routerRedirect()"
                    />
                </div>
                <div
                    class="h2 colorBotticelli reviews__contentNoItem"
                    v-if="reviewsData.length < 1"
                >
                    К сожалению, еще никто не оставил отзывов к этому курсу, Вы можете
                    быть первым.
                </div>
                <div class="reviews__content" v-else>
                    <div
                        class="reviews__contentItem"
                        v-for="item in reviewsData"
                        :key="item.reviewsCardAuthor"
                    >
                        <b-card v-if="isLoadingReviewsData">
                            <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
                        </b-card>
                        <ReviewsCard
                            v-else
                            :cardIcon="`${$apiUrl.slice(0, -1) + item.user.photo}`"
                            :firstName="item.user.firstName"
                            :lastName="item.user.lastName"
                            :cardDate="timeConverter(item.createdAt)"
                            :cardDescriptionImpression="item.text"
                            :cardDescriptionLiked="item.text2"
                            :cardDescriptionImprove="item.text3"
                            :onClick="() => onOpenModal(item._id)"
                        />
                    </div>
                    <ReviewModal
                        v-if="isVisibleModal"
                        :cardIcon="`${$apiUrl.slice(0, -1) + selectedItem.user.photo}`"
                        :firstName="selectedItem.user.firstName"
                        :lastName="selectedItem.user.lastName"
                        :cardDate="timeConverter(selectedItem.createdAt)"
                        :cardDescriptionImpression="selectedItem.text"
                        :cardDescriptionLiked="selectedItem.text2"
                        :cardDescriptionImprove="selectedItem.text3"
                        :onClose="() => onCloseModal()"
                    />
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/Layout";
import SideBar from "@/views/partials/sideBar/sideBar";
import Button from "@/views/components/Button/Button";
import TooltipButton from "@/views/components/TooltipButton/TooltipButton";
import ReviewsCard from "@/views/components/ReviewsCard/ReviewsCard";
import PageHeader from "@/views/partials/pageHeader/pageHeader";
import ReviewModal from "@/views/pages/reviews/reviewModal/reviewModal";

import { getDateFromDatetime } from "../../../helpers/dateFormat";

export default {
    name: "knowledgeBase",
    components: {
        Layout,
        SideBar,
        Button,
        TooltipButton,
        ReviewsCard,
        ReviewModal,
        PageHeader,
    },

    async beforeMount() {
        this.updateReviews();
    },

    watch: {
        "$route.params.slug": function() {
            this.updateReviews();
        },
    },

    computed: {
        reviewsData() {
            return this.$store.getters.getReview.review.reviews;
        },

        activeButton() {
            return (
                !this.$store.getters.getReview.review.canCreateNewReview ||
                !(
                    this.$store.getters.getMember &&
                    this.$store.getters.getMember.data &&
                    this.$store.getters.getMember.data.enabled &&
                    this.$store.getters.getMember.data.enabled.review
                )
            );
        },

        routerRedirect() {
            this.$router.push(`/${this.$route.params.slug}/reviews/write-review`);
        },
    },

    props: ["onClose"],

    data() {
        return {
            title: "Отзывы",

            isVisibleModal: false,
            isLoadingReviewsData: false,

            selectedItem: [],
            date: "",
        };
    },
    methods: {
        onOpenModal(id) {
            this.selectedItem = this.reviewsData.filter((item) => item._id === id)[0];
            this.isVisibleModal = true;
        },

        timeConverter(date) {
            return getDateFromDatetime(date);
        },

        onCloseModal() {
            this.isVisibleModal = false;
        },

        async updateReviews() {
            this.isLoadingReviewsData = true;
            const getReview = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/reviews"
            );

            if (getReview && getReview.data && getReview.data.result) {
                await this.$store.dispatch("setReview", getReview.data);
            } else {
                console.error(
                    "Ошибка при поиске отзывов: " + (getReview.data.message || "")
                );
                await this.$store.dispatch("clearReview");
            }
            this.isLoadingReviewsData = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "reviews.scss";
</style>
